import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    created: '',
    email: '',
    firstName: '',
    id: 1,
    isVerified: false,
    jwtToken: '',
    lastName: '',
    role: '',
    title: '',
    updated: null,
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => action.payload,
        userLoggedOut: () => initialState,
    },
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
