import ApiService from './ApiService'

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: '/Accounts/authenticate',
        method: 'post',
        data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/Accounts/register',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/Accounts/revoke-token',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/Accounts/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/Accounts/reset-password',
        method: 'post',
        data,
    })
}
export async function apiVerifyEmail(data) {
    return ApiService.fetchData({
        url: '/Accounts/verify-email',
        method: 'post',
        data,
    })
}
